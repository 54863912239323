import { FunctionComponent, useRef } from 'react';
import useFeature from 'hooks/useFeature';
import { IHeadingWithPromoListProps } from 'typings/IHeadingWithPromoListProps';
import FormatHeading from 'components/FormatHeading';
import usePromotionDimensions from 'hooks/usePromotionDimensions';
import VideoPreview from 'components/VideoItem/VideoPreview';
import VideoModel from 'models/video.model';
import {
  TEALIUM_EVENT_NAME,
  TEALIUM_EVENT_CATEGORY,
  TEALIUM_EVENT_LABEL,
  IMAGE_MEDIA_TYPE,
  CLIENT,
  FEATURE_SLUG,
  PROGRAM
} from '../../constants';
import ImageMedia from '../ImageMedia';
import {
  HeadingWithPromoListStyle,
  PromoImageStyle,
  HeadingWithPromoLogoStyle,
  HeadingPromoScrollContainerStyle,
  HeadingWithPromoWrapperStyle,
  HeadingWithPromoItemStyle,
  HeadingWithPromoItemContentStyle,
  HeadingWithPromoTitleStyle,
  HeadingWithPromoDateStyle,
  HeadingWithPromoInnerWrapperStyle,
  HeadingRecommendedStyle,
  HeadingWithPromoItemLayout,
  HeadingWithPromoSubtitleStyle
} from './HeadingWithPromoList.style';
import { Grid, GridElement } from '../../styles/grid';
import Link from '../Link';
import { TextLabelStyle } from '../VideoItem/VideoItemDescription/VideoItemDescription.style';
import PlayButton from '../PlayButton';

const HeadingWithPromoList: FunctionComponent<IHeadingWithPromoListProps> = ({
  viewMode,
  logoMedia,
  promotions,
  isBrandPage,
  recommendedTitle
}) => {
  const { enabled: previewEnabled } = useFeature(FEATURE_SLUG.VIDEO_PREVIEW);
  const promoImageContainer = useRef<HTMLDivElement>(null);
  const { width, height } = usePromotionDimensions(promoImageContainer);
  const numOfPromotions = isBrandPage ? 3 : 4;

  if (promotions && promotions.length > numOfPromotions) promotions.length = numOfPromotions;
  const firstPromotion = promotions?.[0];
  const { imageMediaId: headerMedia } = promotions?.[0] || {};

  return (
    <HeadingWithPromoListStyle data-testid="headingWithPromoList" isBrandPage={isBrandPage}>
      {headerMedia && (
        <PromoImageStyle ref={promoImageContainer}>
          <ImageMedia
            publicId={headerMedia}
            shouldLazyLoad={false}
            imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
            transformations={
              viewMode === CLIENT.DESKTOP
                ? {
                    width,
                    height,
                    crop: 'fill',
                    gravity: 'faces:auto',
                    fetch_format: 'auto',
                    aspect_ratio: isBrandPage ? undefined : '16:9'
                  }
                : {
                    width: '1000',
                    crop: 'fill',
                    gravity: 'faces:auto',
                    fetch_format: 'auto',
                    aspect_ratio: isBrandPage ? undefined : '4:3'
                  }
            }
          />
        </PromoImageStyle>
      )}
      <HeadingWithPromoInnerWrapperStyle>
        {logoMedia && (
          <HeadingWithPromoLogoStyle data-testid="brandHeadingLogo">
            <Grid>
              <GridElement smColumn="1 / 13" mdColumn="1 / 13" lgColumn="1 / 13">
                <ImageMedia
                  publicId={logoMedia}
                  imageMedia={{ type: IMAGE_MEDIA_TYPE.REMOTE }}
                  transformations={{ height: '139', crop: 'fill', fetch_format: 'auto' }}
                  shouldLazyLoad={false}
                />
              </GridElement>
            </Grid>
          </HeadingWithPromoLogoStyle>
        )}
        {!isBrandPage ? (
          <FormatHeading
            {...(firstPromotion?.program as VideoModel)}
            title={firstPromotion?.program?.seriesTitle || ''}
            viewMode={viewMode}
            isFormatPage={false}
          />
        ) : null}
        <HeadingPromoScrollContainerStyle>
          {!isBrandPage && recommendedTitle ? (
            <HeadingRecommendedStyle>{recommendedTitle}</HeadingRecommendedStyle>
          ) : null}
          <HeadingWithPromoWrapperStyle>
            {promotions &&
              promotions.slice(isBrandPage ? 0 : 1).map(({ program, path, slug }, index) => (
                <HeadingWithPromoItemStyle key={slug} data-testid={`headingPromo${index + 1}`}>
                  <Link
                    path={path}
                    clickEventValues={{
                      category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
                      name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
                      label: recommendedTitle || TEALIUM_EVENT_LABEL.RECOMMENDED,
                      defaultValues: {
                        event_value: index + 1,
                        ...program?.metadata
                      }
                    }}
                  >
                    <HeadingWithPromoItemLayout>
                      {previewEnabled && <VideoPreview image={program?.imageLandscape.src} guid={program.guid} />}
                      <ImageMedia
                        publicId={program.imageLandscape.src}
                        title={program.title}
                        imageMedia={{ type: program.imageLandscape.type }}
                        shouldLazyLoad={false}
                        transformations={{
                          width: '411',
                          crop: 'fill',
                          aspect_ratio: '16:9',
                          gravity: 'face:auto',
                          fetch_format: 'auto'
                        }}
                      />
                      <HeadingWithPromoItemContentStyle>
                        {program.isAvailable && !previewEnabled && <PlayButton />}
                        {program.labelText && (
                          <TextLabelStyle suppressHydrationWarning>{program.labelText}</TextLabelStyle>
                        )}
                        <HeadingWithPromoTitleStyle data-testid={`headingPromoTitle${index + 1}`}>
                          {program.series?.title || program.title}
                        </HeadingWithPromoTitleStyle>
                        {program.type === PROGRAM.CLIP && (
                          <HeadingWithPromoSubtitleStyle>{program.subtitle}</HeadingWithPromoSubtitleStyle>
                        )}
                        {program.dateLabel && (
                          <HeadingWithPromoDateStyle
                            data-testid={`headingPromoAirDate${index + 1}`}
                            suppressHydrationWarning
                          >
                            {program.videoDateTime}
                          </HeadingWithPromoDateStyle>
                        )}
                      </HeadingWithPromoItemContentStyle>
                    </HeadingWithPromoItemLayout>
                  </Link>
                </HeadingWithPromoItemStyle>
              ))}
          </HeadingWithPromoWrapperStyle>
        </HeadingPromoScrollContainerStyle>
      </HeadingWithPromoInnerWrapperStyle>
    </HeadingWithPromoListStyle>
  );
};

export default HeadingWithPromoList;
