import getConfig from 'next/config';
import { utcToZonedTime } from 'date-fns-tz';
import { DYNAMIC_PAGE } from '../../client/constants';

const { publicRuntimeConfig } = getConfig();

const getTimedHomeSlug = (timedHomeVariables: ITimedHomeFeatureVariables[]): string => {
  if (!Array.isArray(timedHomeVariables)) return DYNAMIC_PAGE.HOME_SLUG;
  const currentHour = utcToZonedTime(new Date(), publicRuntimeConfig.app.timezone).getHours();
  const { slug } = timedHomeVariables.find(({ start, end }) => currentHour >= start && currentHour < end) || {};

  return slug || DYNAMIC_PAGE.HOME_SLUG;
};

export default getTimedHomeSlug;
