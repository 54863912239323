import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import useImprovedQuery from 'hooks/useImprovedQuery';
import useFeature from 'hooks/useFeature';
import CustomGradient from 'components/CustomGradient';
import { HomeStyle, HomeWrapperStyle, HomeOverviewStyle } from 'components/Home/Home.style';
import ErrorBoundary from 'components/ErrorBoundary';
import CommonFallback from 'components/CommonFallback';
import PromoImageHeadingFallback from 'components/PromoImageHeading/PromoImageHeadingFallback';
import { SkeletonHeadingWithPromoList, SkeletonHomeMissed } from 'components/SkeletonElement/SkeletonElement';
import HeadingWithPromoList from 'components/HeadingWithPromoList';
import EditorialOverview from 'components/EditorialOverview';
import OverviewFallback from 'components/Home/OverviewFallback';
import featureTooling from 'components/FeatureTooling/FeatureTooling';
import { isServer } from 'lib/isBrowser';
import { getViewMode, setCookie } from 'lib/helpers';
import getTimedHomeSlug from 'lib/helpers/getTimedHomeSlug';
import EditorialOverviewQuery from 'queries/overview/overview.query';
import OverviewModel from 'models/overview.model';
import { IWithInitialProps } from 'typings/IWithInitialProps';
import { IGetInitialProps } from 'typings/IGetInitialProps';
import { handle301, handle404, handle500 } from 'lib/pageHelpers';
import {
  PROMO_QUERY_CLIENT,
  FALLBACK_UI_LABELS,
  CONFIG_TYPES,
  FEATURE_SLUG,
  DYNAMIC_PAGE
} from '../src/client/constants';
import Error from '../pages/404';

const ContentContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <HomeWrapperStyle data-testid="homeWrapperStyle">
    <HomeStyle data-testid="onDemandStyle">
      <ErrorBoundary onError={() => <CommonFallback message={FALLBACK_UI_LABELS.EDITORIAL_OVERVIEW} />}>
        <HomeOverviewStyle data-testid="editorialRowWrapper">{children}</HomeOverviewStyle>
      </ErrorBoundary>
    </HomeStyle>
  </HomeWrapperStyle>
);

const DynamicPage: IWithInitialProps<{ overviewSlug: string }> = ({ viewMode, overviewSlug }) => {
  const router = useRouter();
  const {
    enabled,
    variant,
    variables: timedHomeVariables
  } = useFeature<ITimedHomeFeatureVariables[]>(FEATURE_SLUG.TIMED_HOME_CONTENT);

  const isHome = router.asPath === '/';

  React.useEffect(() => {
    if (enabled !== null && isHome) {
      setCookie(FEATURE_SLUG.TIMED_HOME_CONTENT, variant || '', 30);
    }
  }, [isHome, enabled, variant]);

  const slug =
    overviewSlug === DYNAMIC_PAGE.HOME_SLUG && timedHomeVariables ? getTimedHomeSlug(timedHomeVariables) : overviewSlug;

  const {
    processedData: overview,
    loading: loadingOverview,
    error
  } = useImprovedQuery<
    {
      overview: IEditorialOverviewData;
    },
    OverviewModel
  >(EditorialOverviewQuery, {
    variables: {
      slug,
      client: PROMO_QUERY_CLIENT.DESKTOP
    },
    processData: data => {
      const overviewModel = new OverviewModel(data.overview, {
        isOnFormatPage: false
      });
      return overviewModel.isValid && overviewModel.type === CONFIG_TYPES.DEFAULT ? overviewModel : null;
    }
  });

  if ((!loadingOverview && !overview) || error) {
    return (
      <ContentContainer>
        <CustomGradient slug={`${router.query.slug}`} />
        {router.query.slug ? (
          <Error />
        ) : (
          <OverviewFallback viewMode={viewMode} graphqlError={error?.message || 'No overview'} />
        )}
      </ContentContainer>
    );
  }

  const isLoading = loadingOverview && overview?.slug !== slug;

  return (
    <div data-testid="dynamicPage">
      <Head>
        <title>{overview?.metadata?.title ? overview.metadata.title : DYNAMIC_PAGE.DEFAULT_TITLE}</title>
        {overview?.metadata?.description && <meta name="og:description" content={overview.metadata.description} />}
      </Head>
      <CustomGradient slug={`${router.query.slug}`} />
      <ErrorBoundary onError={() => <PromoImageHeadingFallback />}>
        {!isLoading && overview?.headerPromotion?.promotions.length ? (
          <HeadingWithPromoList
            viewMode={viewMode}
            recommendedTitle={overview.headerPromotion.recommendedTitle}
            promotions={overview.headerPromotion.promotions}
          />
        ) : null}
        {isLoading && isHome && <SkeletonHeadingWithPromoList />}
      </ErrorBoundary>
      <ContentContainer>
        {!isLoading && overview?.items?.length ? (
          <EditorialOverview editorialOverviewItems={overview.items} viewMode={viewMode} />
        ) : null}
        {isLoading && <SkeletonHomeMissed />}
      </ContentContainer>
    </div>
  );
};

DynamicPage.getInitialProps = async ({ apolloClient, query, req }: IGetInitialProps): Promise<any> => {
  let slug = query.slug || DYNAMIC_PAGE.HOME_SLUG;

  if (!isServer) return { overviewSlug: slug };

  if (query.slug === DYNAMIC_PAGE.HOME_SLUG) {
    return handle301(DYNAMIC_PAGE.HOME_PATH);
  }

  if (slug === DYNAMIC_PAGE.HOME_SLUG && req) {
    await featureTooling.init(process.env.DATA_FILE_URL as string, getViewMode({ req }));
    const timedHomeCookie = req.cookies[FEATURE_SLUG.TIMED_HOME_CONTENT];

    const timedHomeFeature = featureTooling.features.find(feature => feature.slug === FEATURE_SLUG.TIMED_HOME_CONTENT);
    const timedHomeVariant =
      timedHomeFeature?.enabled &&
      timedHomeFeature.experiment?.variants.find(variant => variant.key === timedHomeCookie);
    if (timedHomeVariant && timedHomeVariant.featureEnabled) {
      const variables = timedHomeVariant.useDefaultVariables ? timedHomeFeature?.variables : timedHomeVariant.variables;
      slug = getTimedHomeSlug(variables);
    }
  }

  let overview: OverviewModel | null = null;
  try {
    const { data } = await apolloClient.query<{ overview: IEditorialOverviewData }>({
      query: EditorialOverviewQuery,
      variables: {
        slug,
        client: PROMO_QUERY_CLIENT.DESKTOP
      }
    });
    overview = new OverviewModel(data.overview);
  } catch {
    return handle500('Could not fetch overview');
  }

  if (!overview.isValid || overview.type !== CONFIG_TYPES.DEFAULT) {
    return query.slug ? handle404(`Overview is not valid`) : {};
  }
  return { overviewSlug: slug };
};

export default DynamicPage;
