import React, { FunctionComponent, useState, useEffect } from 'react';
import EditorialOverview from 'components/EditorialOverview';
import featureTooling from 'components/FeatureTooling';
import PlaceholderModel from 'models/placeholder.model';
import { IPlaceholderData } from 'typings/IPlaceholderData';
import { FEATURE_SLUG } from '../../../constants';

const OverviewFallback: FunctionComponent<IOverviewFallbackProps> = ({ viewMode, graphqlError }) => {
  const [fallbackItems, setFallbackItems] = useState<PlaceholderModel[]>([]);

  useEffect(() => {
    if (window.newrelic && graphqlError) {
      window.newrelic.noticeError(new Error(`Home overview can't be fetched`), {
        graphql_error: graphqlError
      });
    }

    featureTooling.waitUntilReady().then(() => {
      const fallbackOverviewItems = featureTooling.getFeatureVariables<IPlaceholderData[]>(FEATURE_SLUG.HOME_FALLBACK);
      if (fallbackOverviewItems) {
        setFallbackItems(fallbackOverviewItems.map(fallbackItem => new PlaceholderModel(fallbackItem)));
      }
    });
  }, [graphqlError]);

  if (!fallbackItems.length) return null;
  return <EditorialOverview editorialOverviewItems={fallbackItems} viewMode={viewMode} />;
};

export default OverviewFallback;
